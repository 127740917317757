import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Head from 'next/head';
import PropTypes from 'prop-types';
import * as React from 'react';
import { SettingsConsumer, SettingsProvider } from '../settings/settings-context';
import { createEmotionCache } from '../settings/create-emotion-cache';
import { createTheme } from '../theme';
import { configureAppStore } from "../store/configureStore";
import 'chartjs-plugin-datalabels';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { UserProvider } from '@auth0/nextjs-auth0';
import * as ga from '../lib/ga'
import { useRouter } from 'next/router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import zoomPlugin from 'chartjs-plugin-zoom';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

async function registerPlugin() {
  if (typeof window !== 'undefined') {
    const zoomPlugin = await import('chartjs-plugin-zoom');
    Chart.register(zoomPlugin.default);
  }
}

const MyApp = (props) => {
  const router = useRouter()
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  React.useEffect(() => {
    const handleRouteChange = (url: any) => {
      ga.pageview(url)
    }
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])
  if (registerables)
    Chart.register(...registerables);
  Chart.register(ChartDataLabels);
  registerPlugin();
  Chart.defaults.set('plugins.datalabels', {
    color: '#aaa',
    align: 'end',
    anchor: 'end',
    formatter: function (value, context) {
      const string = value < 1000? value: roundToK(value);
      return '₹' + string;
    },
  });
function roundToK(value){
  return Math.round(((value/1000) + Number.EPSILON) * 100) / 100  + 'k';
}

  return (
    <UserProvider>
      <CacheProvider value={emotionCache}>
        <SettingsProvider>
          <SettingsConsumer>
            {({ settings }) => (<ThemeProvider theme={createTheme({
              mode: settings.theme
            })}>
              <Head>
                <meta name="viewport" content="initial-scale=1, width=device-width" />
              </Head>
              <ToastContainer
                position="top-right"
                autoClose={8000}
                hideProgressBar={false}
                newestOnTop={false}
                draggable={false}
                closeOnClick
                pauseOnHover
              />
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <Component {...pageProps} />
            </ThemeProvider>
            )}
          </SettingsConsumer>
        </SettingsProvider>
      </CacheProvider>
    </UserProvider>
  );
}

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
};

export default configureAppStore().withRedux(MyApp);